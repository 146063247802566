import React, { useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Button,
  Label,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import {
  fetchAllStoresListing,
  fetchAllClerkListing,
  saveCoinFormDetails,
  resetAddCoinForm,
  saveCoinItemDetails,
  saveCoinBarterItemDetails
} from "../../../redux/CoinTicket/coinAction";

import {
  copyTicketDetailsToMetro
} from "../../../redux/Tickets/ticketAction";

import { DropdownList } from "react-widgets";
import Unauthorized from "views/Pages/Unauthorized";
import AddCoinTicketForm from "views/Forms/AddCoinTicket/AddCoinTicketForm";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { resetState } from "../../../redux/common/commonAction";
import { toast } from "react-toastify";
import { validateTicketForm } from "constantFunctions/formValidations";
import { jsonToFormData } from "libs/common";

function AddNewMetroTicket(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [copyTo, setCopyTo] = useState({ value: "ticket", label: "Ticket" });
  const [blocked, setBlocked] = useState(false);
  const [censored, setCensored] = useState(false);
  const [loading, setLoading] = useState(false);

  const saveHandler = (e) => {
    if(props.items.items.length > 0){
    let data = jsonToFormData({
      details: {
        ...props.coinFormDetails,
        payment: props.coinFormDetails.payment.value,
        sex: props.coinFormDetails.sex.value,
        eyes: props.coinFormDetails.eyes.value,
        hair: props.coinFormDetails.hair.value,
        booth: props.coinFormDetails.booth.name,
        station: props.coinFormDetails.station.value,
        copyTo: props.coinFormDetails.copyTo.value,
        race: props.coinFormDetails.race.value,
        storeId: props.coinFormDetails.storeId.id,
        clerk: props.coinFormDetails.clerk.first_name,
        fromx: props.coinFormDetails.fromx.name,
      },
      items: props.items.items,
      barterItems: props.barterItems.items,
    });
    window.jQuery('[name="files[]"]').each(function( index ) {
      let files = window.jQuery('[name="files[]"]')[index].files;
      for (const file of files) {
        data.append('files[]', file, file.name);
      }
    });
      setLoading(true);
      PostApiWithAuthorizationAction("coin-tickets/create-new", data, {
        'Content-Type': 'multipart/form-data'
      })
      .then((res) => {
        setLoading(false);
        //toast.success(res.message);
        props.resetForm();
        props.history.push("/portal/coin-tickets");
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } else {
          toast.error(err.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    } else {
      toast.error("*Required at least 1 Item in Property Description.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card>
          <CardHeader
            className={
              blocked
                ? ((censored ? "back-green" : "back-red") + " d-flex justify-content-between p-2")
                : "bg-grn d-flex justify-content-between p-2"
            }
          >
            <h3 className="text-white">Add Coin Ticket</h3>
            {/* <div className="d-flex copy-drop align-items-center"> 
            <div className="d-flex copy-drop justify-content-end align-items-center">
                <Label className="m-0">Copy to:</Label>
                <DropdownList
                  data={[{ value: "ticket", label: "Ticket" }]}
                  containerClassName="race-drop"
                  textField="label"
                  value={copyTo}
                  onChange={(e) => setCopyTo(e)}
                />
                <Button className="ml-1" color="info" onClick={e => copyToNew()}>
                  Copy
                </Button>
              </div> */}
              <Button
                type="button"
                className="py-2 ml-2"
                color="primary"
                onClick={() => {
                  props.history.goBack();
                }}
              >
                <i className="fas fa-chevron-left"></i> Back
              </Button>
            {/* </div> */}
          </CardHeader>
          <CardBody className={blocked ? (censored ? "back-green" : "back-red") + " p-2" : "bg-grn p-2"}>
            <AddCoinTicketForm {...props} blocked={setBlocked} censored={setCensored}/>
            <div className="text-center mt-3">
              {blocked ? (
                <Button type="button" color="primary" disabled>
                  Blocked
                </Button>
              ) : loading ? (
                <Button color="primary" disabled>
                  loading <i className="fas fa-spinner fa-spin"></i>
                </Button>
              ) : (
                <Button
                id="submit-ticket"
                  type="buttton"
                  color="primary"
                  onClick={(e) => {
                    // saveCoinTicket
                    //let items = props.coinFormDetails.payment.value != "barter" ? props.items.items : props.barterItems.items;
                    let validation = validateTicketForm({
                      ... props.coinFormDetails,
                      userType: props.userType
                    }, props.items.items);
                    if (validation.success) {
                      saveHandler();
                    } else {
                      toast.error(validation.err, {
                        position: toast.POSITION.TOP_CENTER,
                        closeOnClick: true,
                        autoClose: 4000,
                      });
                    }
                  }}
                >
                  Submit
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}
// }

const mapStateToProps = (state) => {
  return {
    stores: state.coinReducer.stores,
    clerks: state.coinReducer.clerks,
    coinFormDetails: state.coinReducer.addCoinFormdetails,
    userType: state.loginUserReducer.userType,
    items: state.coinReducer.coinItemdetails,
    barterItems: state.coinReducer.coinBarterItemdetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllStoresListing: () => dispatch(fetchAllStoresListing()),
    fetchAllClerkListing: () => dispatch(fetchAllClerkListing()),
    saveCoinFormDetails: (body) => dispatch(saveCoinFormDetails(body)),
    saveCoinItemDetails: (body) => dispatch(saveCoinItemDetails(body)),
    saveCoinBarterItemDetails: (body) => dispatch(saveCoinBarterItemDetails(body)),
    resetState: () => dispatch(resetState()),
    copyTicketDetailsToMetro:(body) => dispatch(copyTicketDetailsToMetro(body)),
    resetForm: () => dispatch(resetAddCoinForm()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewMetroTicket);
