import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
} from "reactstrap";
import InputMask from "react-input-mask";
import {
  emailRegex,
  getallErrors,
} from "../../../constantFunctions/formValidations";
import { pettyCashOptions } from "../../../constants/ticketConstants";
import moment from "moment";

function AddPettyCashTransaction(props) {
  const [users, setUsers] = useState([]);
  const [transactionData, setTransactionData] = useState({
    src: "pettycash",
    dest: "customer",
    who: "",
    time: moment().format("YYYY-MM-DD"),
    denom100: 0,
    //denom50: 0,
    denom20: 0,
    denom10: 0,
    denom5: 0,
    denom1: 0,
    comment: "",
    eMail: "",
    SSN: "",
  });

  useEffect(() => {
    fetchAllPCUsers();
  }, []);

  const [formErrors, setFormErrors] = useState({
    eMail: "",
    comment: "",
    SSN: "",
    dest: ""
  });

  const fetchAllPCUsers = () => {
    //if (e) {
      GetApiActionWithAuthorization("common/get-all-petty-cash-user").then(
        (res) => {
          setUsers(res.data);
        }
      );
    //}
  };

  const submitHandler = (e) => {
    let total =
      transactionData.denom100 * 100 +
      //transactionData.denom50 * 50 +
      transactionData.denom20 * 20 +
      transactionData.denom10 * 10 +
      transactionData.denom5 * 5 +
      transactionData.denom1 * 1;
    var valid =
      transactionData.dest!=="" && transactionData.comment.length > 0;
    /*if (total >= 600) {
      valid =
        transactionData.SSN.length === 11 
        //&&
        //transactionData.eMail.length > 0 &&
        //emailRegex.test(transactionData.eMail);
    }*/
    if (valid) {
      let body = {
        ...transactionData,
        //dest: "PettyCash-" + transactionData.dest.name,
        dest: transactionData.dest,
        email: transactionData.eMail,
        SSN:total >= 600 ? transactionData.SSN:""
      };
      PostApiWithAuthorizationAction(
        "common/create-new-transaction",
        body
      ).then((res) => {
        props.fetchTransactionListing({
          returnCount: true
        });
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        props.toggle();
      });
    } else {
      let errors = getallErrors({
        formValue: {
          ...transactionData,
          totalTransAmount: total,
        },
        formErrors,
      });
      setFormErrors({ ...errors });
    }
  };

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (
      value.endsWith("-") &&
      userInput !== "-" &&
      !transactionData.SSN.endsWith("-")
    ) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };
  return (
    <Modal size="lg" isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        Create New Petty Cash Transaction
      </ModalHeader>
      <ModalBody>
        <Table className="transaction-table" striped bordered>
          <tbody>
            <tr>
              <td style={{ width: "100px" }}>Date:</td>
              <td colSpan="2" className="date">
                <Input
                    type="date"
                    value={transactionData.time}
                    onChange={(e) => setTransactionData({
                      ...transactionData,
                      time: e.target.value,
                    })}
                  />
              </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>Comment:</td>
              <td colSpan="2" className="comment">
                <Input
                  type="text"
                  placeholder="Comment"
                  value={transactionData.comment}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      comment: e.target.value,
                    });
                  }}
                />
                {formErrors.comment.length > 0 && (
                  <span className="text-danger">
                    <small>{formErrors.comment}</small>
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>For Who:</td>
              <td colSpan="2">
                <div className="d-flex align-items-end">
                  <DropdownList
                    id="for-who"
                    data={users}
                    textField="name"
                    valueField="name"
                    name="name"
                    containerClassName="from-drop"
                    value={transactionData.who}
                    onChange={(e) => {
                      setTransactionData({
                        ...transactionData,
                        comment: 'For: ' + e.name,
                        who: e.name
                      });
                    }}
                  />
                </div>
                {formErrors.dest.length > 0 && (
                  <span className="text-danger ml-2">
                    <small>{formErrors.dest}</small>
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>$100</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom100}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom100: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td> ${100 * transactionData.denom100}</td>
            </tr>
            {/*<tr>
              <td style={{ width: "100px" }}>$50</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom50}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom50: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td>${50 * transactionData.denom50} </td>
              </tr>*/}
            <tr>
              <td style={{ width: "100px" }}>$20</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom20}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom20: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td>${20 * transactionData.denom20} </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>$10</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom10}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom10: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td>${10 * transactionData.denom10} </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>$5</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom5}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom5: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td> ${5 * transactionData.denom5} </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>$1</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom1}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom1: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td> ${1 * transactionData.denom1}</td>
            </tr>
            <tr>
              <td colSpan="2">Total:</td>
              <td>
                $
                {transactionData.denom100 * 100 +
                  //transactionData.denom50 * 50 +
                  transactionData.denom20 * 20 +
                  transactionData.denom10 * 10 +
                  transactionData.denom5 * 5 +
                  transactionData.denom1 * 1}{" "}
              </td>
            </tr>
            {transactionData.denom100 * 100 +
              //transactionData.denom50 * 50 +
              transactionData.denom20 * 20 +
              transactionData.denom10 * 10 +
              transactionData.denom5 * 5 +
              transactionData.denom1 * 1 >=
              600 && (
              <>
                <tr>
                  <td>SSN:</td>
                  <td colSpan="2">
                    <InputMask
                      mask="999-99-9999"
                      maskChar={null}
                      value={transactionData.SSN}
                      onChange={(e) => {
                        setTransactionData({
                          ...transactionData,
                          SSN: e.target.value,
                        });
                      }}
                      beforeMaskedValueChange={beforeMaskedValueChange}
                    />
                    {formErrors.SSN.length > 0 && (
                      <span className="text-danger">
                        <small>{formErrors.SSN}</small>
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td colSpan="2">
                    <Input
                      type="email"
                      placeholder="Email"
                      value={transactionData.eMail}
                      onChange={(e) => {
                        setTransactionData({
                          ...transactionData,
                          eMail: e.target.value,
                        });
                      }}
                    />
                    {formErrors.eMail.length > 0 && (
                      <span className="text-danger">
                        <small>{formErrors.eMail}</small>
                      </span>
                    )}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={(e) => submitHandler()}>
          Save
        </Button>{" "}
        <Button color="secondary" onClick={(e) => props.toggle()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default AddPettyCashTransaction;
