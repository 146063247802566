import React, { useEffect, useRef, useState } from "react";
import Header from "components/Headers/Header";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import Unauthorized from "views/Pages/Unauthorized";
import { useLocation, Switch, Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchDailyTableData } from "../../redux/Daily/dailyAction";
import { PostApiWithAuthorizationAction, GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";
import { toast } from "react-toastify";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import { resetState } from "../../redux/common/commonAction";
import moment from "moment";
import qs from "query-string";
import DailyTicket from "components/Daily/DailyTicket";
import DailyCoinReceipt from "components/Daily/DailyCoinReceipt";
import DailyJewelryReceipt from "components/Daily/DailyJewelryReceipt";
import MissingDepositModal from "views/Modal/MissingDepositModal"

const monthList = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// reactstrap components

function Daily(props) {
  const location = useLocation();
  const { match: { params } } = props;
  const [unauthorized, setUnauthorized] = useState(false);
  let typeParam = params.year ? "month" : (params.month ? "day" : "year");
  let timeStampParam = params.year ? params.year : null;
  //set month in in route
  if (params.month && typeof monthList[params.month] !== "undefined") {
    typeParam = "day";
    timeStampParam = monthList[params.month];
  }
  const [type, setType] = useState(typeParam);
  const [timeStamp, setTimeStamp] = useState(timeStampParam);
  const yearParam = params.year || "";
  const [year, setYear] = useState(yearParam);
  const [showAsWeek, setShowAsWeek] = useState(false);
  const [storeId, setStoreId] = useState([]);
  const [missingDepositItems, setMissingDepositItems] = useState([]);
  const [missingDepositDialog, setMissingDepositDialog] = useState(false);
  const [hideNCM, setHideNCM] = useState(false);
  const [hideLVCC, setHideLVCC] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [dataType, setDataType] = useState('all');
  const [dailyData, setDailyData] = useState({
    ticket: [],
    coin: [],
    receipt: [],
    jewelryReceipt: [],
    oldCoin: [],
    bullion: [],
    error: null,
    type: 0,
    view: {
      ticket: typeParam,
      coin: typeParam,
      receipt: typeParam,
      jewelryReceipt: typeParam,
      oldCoin: typeParam,
      bullion: typeParam,
      dataType: "all"
    },
    count: {
      metroTotal: 0,
      coinTotal: 0,
      coinDepTotal: 0,
      jewelryDepTotal: 0,
      coinChangeTotal: 0,
      jewelryChangeTotal: 0,
      coinDailyTotal: 0
    }
  });

  //first Load data
  useEffect(() => {
    let dataType = qs.parse(window.location.search, { ignoreQueryPrefix: true }).dataType || "all";
    setDataType(dataType);
    fetchDailyTableData(typeParam, timeStampParam, false, yearParam, storeId, dataType);
    setFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!firstLoad) {
      setYear(yearParam)
      setType(typeParam)
      setTimeStamp(timeStampParam)
      let dataType = qs.parse(window.location.search, { ignoreQueryPrefix: true }).dataType || "all";
      setDataType(dataType);
      fetchDailyTableData(typeParam, timeStampParam, false, yearParam, storeId, dataType);
    }
  }, [location]);

  const confirmed = (dates, depositDone = true, depositDate = null) => {
    //console.log(dates)
    PostApiWithAuthorizationAction("common/bulk-edit-daily-date", {
      dates: dates.filter(v=>v.depositDone === depositDone).map(v=>v.dailyDate),
      dataType,
      depositDate: depositDate ? moment(depositDate).startOf('day').unix() : moment().startOf('day').unix()
    }).then((res) => {
      setMissingDepositDialog(false)
      fetchDailyTableData(typeParam, timeStampParam, false, yearParam, storeId, dataType);
    }).catch((err) => {
      console.log(err);
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

  //Error Handler from Api and unauthorized check
  if (dailyData.error && !unauthorized) {
    if (dailyData.error.status === 403) {
      setUnauthorized(true);
    } else if (dailyData.error.status === 440) {
      props.resetState();
      toast.error(dailyData.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const toggleEdit = () => {
    setMissingDepositDialog(!missingDepositDialog);
  };

  const fetchDailyTableData = (type, timeStamp, filterByWeeks, year, storeId, dataType) => {
    let params = {
      type,
      timeStamp,
      filterByWeeks,
      year,
      storeId,
      dataType
    };
    GetApiActionWithAuthorization("common/daily-data", params)
      .then((res) => {
        let _dailyData = dailyData;
        if (res.data.ticket) {//set ticket when requested
          _dailyData.ticket = res.data.ticket;
        }
        if (res.data.coin) {//set coin
          _dailyData.coin = res.data.coin;
          _dailyData.view.coin = res.data.view.coin;
        }
        if (res.data.oldCoin) {//set oldCoin
          _dailyData.oldCoin = res.data.oldCoin;
          _dailyData.view.oldCoin = res.data.view.oldCoin;
        }
        if (res.data.bullion) {//set bullion
          _dailyData.bullion = res.data.bullion;
          _dailyData.view.bullion = res.data.view.bullion;
        }
        if (res.data.receipt) {//set receipt
          _dailyData.receipt = res.data.receipt;
          _dailyData.view.receipt = res.data.view.receipt;
        }
        if (res.data.jewelryReceipt) {//set receipt
          _dailyData.jewelryReceipt = res.data.jewelryReceipt;
          _dailyData.view.jewelryReceipt = res.data.view.jewelryReceipt;
        }
        _dailyData.view.dataType = res.data.view.dataType;
        //set count
        _dailyData.count = res.data.count;
        //
        setDailyData({ ... _dailyData });
        if (res.data.receipt) {
          const noDepositItems = res.data.receipt.filter(v=>v.depositDone === false && v.checkAmount == 0 && v.dailyDate != moment().format('YYYY-MM-DD'))
          setMissingDepositItems(noDepositItems)
        }
      })
      .catch((err) => {
        props.resetState();
        toast.error(err.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        props.history.push("/auth/login");
      });
  };

  const updateRow = (body) => {
    let dataType = qs.parse(window.location.search, { ignoreQueryPrefix: true }).dataType || "all";
    PostApiWithAuthorizationAction("common/edit-daily-date", body)
      .then((res) => {
        fetchDailyTableData(typeParam, timeStampParam, false, yearParam, storeId, dataType);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          props.history.push("/auth/login");
        }
      });
  };

  const checkboxHandler = (e, date, field, type) => {
    const { checked } = e.target;
    let dataType = qs.parse(window.location.search, { ignoreQueryPrefix: true }).dataType || "all";
    let body = {
      date: moment(date).format('YYYY-MM-DD'),
      [field]: checked,
      type
    };
    /*if (field === 'depositDone' && dataType === 'receipt' && checked) {
      let noDepositItems = dailyData.receipt.filter(v=>v.depositDone === false && v.checkAmount == 0 && ![body.date, moment().format('YYYY-MM-DD')].includes(v.dailyDate))
      if (noDepositItems.length > 0) {
        setMissingDepositItems(noDepositItems)
        setMissingDepositDialog(true)
      }
    }*/
    PostApiWithAuthorizationAction("common/edit-daily-date", body)
      .then((res) => {
        fetchDailyTableData(typeParam, timeStampParam, false, yearParam, storeId, dataType);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          props.history.push("/auth/login");
        }
      });
  };

  // console.log(props);
  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardBody className="page-main-content daily-main-content">
            <div className="text-right">
              {(type === "month" || type === "day") && (
                <Button
                  type="button"
                  className="py-2 ml-2"
                  color="primary"
                  onClick={() => {
                    props.history.goBack();
                  }}
                >
                  <i className="fas fa-chevron-left"></i> Back
                </Button>
              )}
            </div>
            <div
              className="daily-count-total text-center mb-1"
              style={{fontSize: "16px"}}>
                Metro Total:&nbsp;
                <span className="font-weight-600">
                  ${dailyData.count.metroTotal}
                </span>&nbsp;&nbsp;
                Coin Total:&nbsp;
                <span className="font-weight-600">
                  ${dailyData.count.coinTotal}
                </span>&nbsp;&nbsp;
                Coin Dep:&nbsp;
                <span className="font-weight-600">
                  ${dailyData.count.coinDepTotal}
                </span>&nbsp;&nbsp;
                Coin Daily:&nbsp;
                <span className="font-weight-600">
                  ${dailyData.count.coinDailyTotal}
                </span>&nbsp;&nbsp;
                Coin Change:&nbsp;
                <span className="font-weight-600">
                  ${dailyData.count.coinChangeTotal}
                </span>&nbsp;&nbsp;
                Jewelry Dep:&nbsp;
                <span className="font-weight-600">
                  ${dailyData.count.jewelryDepTotal}
                </span>&nbsp;&nbsp;
                Jewelry Change:&nbsp;
                <span className="font-weight-600">
                  ${dailyData.count.jewelryChangeTotal}
                </span>
            </div>
            <div>
              <FormGroup className="form-control-label" check>
                <Label className="form-control-label" check>
                <Input
                    type="checkbox"
                    id="todayOnly"
                    checked={showAsWeek}
                    onChange={(e) => {
                      setShowAsWeek(e.target.checked);
                      fetchDailyTableData(
                        type,
                        timeStamp,
                        e.target.checked
                      );
                    }}
                  />{" "}Show as Days of Week
                </Label><span className="ml-1">| Hide:
                  <Input
                    type="checkbox"
                    id="ncmStore"
                    className="ml-1"
                    style={{position: "relative"}}
                    checked={hideNCM}
                    onChange={(e) => {
                      setHideNCM(e.target.checked);
                      let storeIds = storeId;
                      if (!storeIds.includes(1)) {
                        storeIds.push(1);
                      } else {
                        storeIds = storeIds.filter(s=>s!=1)
                      }
                      setStoreId(storeIds);
                      fetchDailyTableData(
                        type,
                        timeStamp,
                        showAsWeek,
                        yearParam,
                        storeIds
                      );
                    }}
                  />{" "}
                  NCM</span>
              </FormGroup>
            </div>
            {(dailyData.view.dataType == 'ticket') && (
              <DailyTicket
                data={dailyData.ticket}
                year={year}
                timeStamp={timeStamp}
                _props={props}
                settings={{
                  title: 'Metro',
                  dataType:'ticket',
                  singleViewLink: 'metro-tickets',
                  historicalChart: {
                    pathname: "/portal/daily/historical-chart",
                    state: { type: "Ticket" }
                  }
                }}/>
            )}
            {(dailyData.view.dataType == 'bullion') && (
              <DailyTicket
                data={dailyData.bullion}
                year={year}
                timeStamp={timeStamp}
                _props={props}
                settings={{
                  title: 'Bullion',
                  dataType:'bullion',
                  historicalChart: {
                    pathname: "/portal/daily/historical-chart",
                    state: { type: "Bullion" }
                  }
                }}/>
            )}
            {(dailyData.view.dataType == 'coin') && (
              <DailyTicket
                data={dailyData.coin}
                year={year}
                timeStamp={timeStamp}
                _props={props}
                settings={{
                  title: 'Coin',
                  dataType:'coin',
                  singleViewLink: 'coin-tickets',
                  historicalChart: {
                    pathname: "/portal/daily/historical-chart",
                    state: { type: "Coin" }
                  }
                }}/>
            )}
            {(dailyData.view.dataType == 'oldCoin') && (
              <DailyTicket
                data={dailyData.oldCoin}
                year={year}
                timeStamp={timeStamp}
                _props={props}
                settings={{
                  title: 'Old Coin',
                  dataType:'oldCoin',
                  singleViewLink: '',
                  historicalChart: {
                    pathname: "/portal/daily/historical-chart",
                    state: { type: "oldCoin" }
                  }
                }}/>
            )}
            {(dailyData.view.dataType == 'receipt') && (
              <DailyCoinReceipt
                {...props}
                data={dailyData}
                year={year}
                timeStamp={timeStamp}
                setDailyData={setDailyData}
                updateRows={confirmed}
                toggle={toggleEdit}
                missingDepositItems={missingDepositItems}
                checkboxHandler={checkboxHandler}
                updateRow={updateRow}/>
            )}
            {(dailyData.view.dataType == 'jewelryReceipt') && (
              <DailyJewelryReceipt
                {...props}
                data={dailyData}
                year={year}
                timeStamp={timeStamp}
                checkboxHandler={checkboxHandler}/>
            )}
            {(dailyData.view.dataType == 'all') && (
            <Row>
              <Col className="mt-3" md="6" lg="3">
                <DailyTicket
                  data={dailyData.ticket}
                  year={year}
                  timeStamp={timeStamp}
                  _props={props}
                  settings={{
                    title: 'Metro',
                    dataType:'ticket',
                    singleViewLink: 'metro-tickets',
                    historicalChart: {
                      pathname: "/portal/daily/historical-chart",
                      state: { type: "Ticket" }
                    }
                  }}/>
                <DailyTicket
                  data={dailyData.bullion}
                  year={year}
                  timeStamp={timeStamp}
                  _props={props}
                  settings={{
                    title: 'Bullion',
                    dataType:'bullion',
                    singleViewLink: '',
                    historicalChart: {
                      pathname: "/portal/daily/historical-chart",
                      state: { type: "Bullion" }
                    }
                  }}/>
              </Col>
              <Col className="mt-3" md="6" lg="3">
                <DailyTicket
                  data={dailyData.coin}
                  year={year}
                  timeStamp={timeStamp}
                  _props={props}
                  settings={{
                    title: 'Coin',
                    dataType:'coin',
                    singleViewLink: 'coin-tickets',
                    historicalChart: {
                      pathname: "/portal/daily/historical-chart",
                      state: { type: "Coin" }
                    }
                  }}/>
                <DailyTicket
                  data={dailyData.oldCoin}
                  year={year}
                  timeStamp={timeStamp}
                  _props={props}
                  settings={{
                    title: 'Old Coin',
                    dataType:'oldCoin',
                    singleViewLink: '',
                    historicalChart: {
                      pathname: "/portal/daily/historical-chart",
                      state: { type: "oldCoin" }
                    }
                  }}/>
              </Col>
              <Col className="mt-3" md="6" lg="6">
                <DailyCoinReceipt
                  {...props}
                  data={dailyData}
                  year={year}
                  timeStamp={timeStamp}
                  checkboxHandler={checkboxHandler}
                  missingDepositItems={missingDepositItems}
                  toggle={toggleEdit}
                  updateRows={confirmed}
                  setDailyData={setDailyData}
                  updateRow={updateRow}/>
                <DailyJewelryReceipt
                  {...props}
                  data={dailyData}
                  year={year}
                  timeStamp={timeStamp}
                  setDailyData={setDailyData}
                  checkboxHandler={checkboxHandler}
                  updateRow={updateRow}/>
              </Col>
            </Row>
          )}
          </CardBody>
        </Card>
      </Container>
      {missingDepositDialog && (
        <MissingDepositModal
          {...props}
          show={missingDepositDialog}
          items={missingDepositItems}
          toggle={toggleEdit}
          setMissingDepositDialog={setMissingDepositDialog}
          confirmed={confirmed}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    dailyData: state.dailyReducer,
    userType: state.loginUserReducer.userType
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDailyTableData: (type, timeStamp, filter, year, storeId) =>
      dispatch(fetchDailyTableData(type, timeStamp, filter, year, storeId)),
    // addUpdatedData: (data) => dispatch(fetchFromOptionsListingSuccess(data)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Daily);
