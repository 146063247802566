import React, { useEffect, useState } from "react";
import {
  Input,
  Label,
  Form,
  Table,
  InputGroup,
  InputGroupAddon,
  Button
} from "reactstrap";
import {
  sexOptions,
  hairOptions,
  metroPaymentOptions,
  eyesOptions,
  stationOptions,
  raceOptions,
} from "../../../constants/ticketConstants";
import { DropdownList } from "react-widgets";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { enableFileUploader } from "libs/common";
import moment from "moment-timezone";

function AddMetroTicketForm(props) {
  const [keywords, setKeywords] = useState([]);
  const [keyLoad, setKeyLoad] = useState(false);
  const [fromOptions, setFromOption] = useState([]);
  const [boothOptions, setBoothOptions] = useState([]);
  const [formBlocked, setFormBlocked] = useState(false);
  const [checkNumberPrefix, setCheckNumberPrefix] = useState('');

  useEffect(() => {
    //let id = props.match.params.id;
    if (localStorage.getItem("lastUsedPrinter")) {
      props.saveTicketFormDetails({
        name: "station",
        value: stationOptions.find(
          (s) => s.value === localStorage.getItem("lastUsedPrinter")
        ),
      });
    }
    props.fetchAllStoresListing();
    fetchAllBooth();
    fetchAllClerks();
    getFromOptions();
    //load fileuploader with plain jquery
    window.jQuery(document).ready(function() {
      enableFileUploader();
    });
    GetApiActionWithAuthorization("common/get-check-setting").then((res) => {
      props.saveTicketFormDetails({
        name: "checkNumber",
        value: res.data.metroCheckNumberPrefix,
      });
      //
      setCheckNumberPrefix(res.data.metroCheckNumberPrefix.toString());
    });
  }, []);

  const fetchAllClerks = (e) => {
    props.fetchAllClerkListing();
  };
  const fetchAllBooth = (e) => {
    GetApiActionWithAuthorization("common/get-all-booths").then((res) => {
      setBoothOptions(res.data);
    });
  };

  const changeFirstNameAndLastNameHandler = (e) => {
    const { name, value } = e.target;
    props.saveTicketFormDetails({
      name: name,
      value: value,
    });
    let secondName = name === "firstName" ? "lastName" : "firstName";
    if (props.metroFormDetails[secondName] !== "" && value !== "") {
      checkFormBlocked({
        [name]: value.trim(),
        [secondName]: props.metroFormDetails[secondName].trim(),
      });
    }
  };

  const checkFormBlocked = (body) => {
    PostApiWithAuthorizationAction("common/check-block-pass", body).then((res) => {
      props.blocked(res.blocked);
      setFormBlocked(res.blocked);
    });
  };

  const changeDlAndOtherNumberHandler = (e) => {
    const { name, value } = e.target;
    props.saveTicketFormDetails({
      name,
      value,
    });
    if (value !== "") {
      let body = {
        [name]: value.trim()
      };
      let firstName = props.metroFormDetails.firstName;
      if (firstName.length > 0) {
        body.firstName = firstName;
      //}
      PostApiWithAuthorizationAction(
        "common/fetch-other-details",
        body
      ).then((res) => {
        //props.blocked(res.blocked);
        let details = res.otherDetails;
        //
        if (details) {
          checkFormBlocked({
            firstName: details.firstName,
            lastName: details.lastName
          });

          props.saveTicketFormDetails({
            name: "fromx",
            value: fromOptions[1],
          });

          for (const item in details) {
            if (item === "race") {
              props.saveTicketFormDetails({
                name: item,
                value: raceOptions.find((r) => r.value === details[item]),
              });
            }/* else if (item === "sex") {
              props.saveTicketFormDetails({
                name: item,
                value: sexOptions.find((r) => r.value === details[item]),
              });
            } else if (item === "eyes") {
              props.saveTicketFormDetails({
                name: item,
                value: eyesOptions.find((r) => r.value === details[item]),
              });
            } else if (item === "hair") {
              props.saveTicketFormDetails({
                name: item,
                value: hairOptions.find((r) => r.value === details[item]),
              });
            } else {
              props.saveTicketFormDetails({
                name: item,
                value: details[item],
              });
            }*/
          }

          //if not Blocked set from to repeat
          if (!formBlocked) {
            props.saveTicketFormDetails({
              name: "fromx",
              value: { name: "repeat" },
            });
          }
        }
      });
      }
    }
  };

  // const getKeywords = (e) => {
  //   if (keywords.length === 0) {
  //     GetApiActionWithAuthorization("common/get-keywords-for-form")
  //       .then((res) => {
  //         setKeywords(res.data);
  //       })
  //       .catch((err) => setKeyLoad(false));
  //   }
  // };

  const getFromOptions = (e) => {
    //if (e && fromOptions.length === 0) {
      setKeyLoad(true);
      GetApiActionWithAuthorization("common/get-from-select-options")
        .then((res) => {
          setKeyLoad(false);
          setFromOption(res.data);
        })
        .catch((err) => setKeyLoad(false));
    //}
  };

  return (
    <Form encType="multipart/form-data" id="form-save-ticket-form">
      <Table className="add-ticket-table" bordered responsive="sm">
        <tbody>
          <tr className="back-grey">
            <th>Ticket Amount:</th>
            <td className="width-middle">
              <Input
                id="ticketAmount"
                type="number"
                step="1"
                placeholder="Ticket Amount"
                autoComplete="off"
                name="ticketAmount"
                value={props.metroFormDetails.ticketAmount}
                onChange={(e) => {
                  props.saveTicketFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                autoFocus={true}
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr
            className={
              props.metroFormDetails.payment.value === "cash"
                ? "back-green"
                : "back-orange"
            }
          >
            <th>Payment:</th>
            <td
              className="width-middle"
              colSpan={
                props.metroFormDetails.payment.value === "check" ? "2" : 1
              }
            >
              <div className="d-flex align-items-end check">
                <DropdownList
                  id="payment"
                  data={metroPaymentOptions}
                  containerClassName="payment-drop"
                  textField="label"
                  value={props.metroFormDetails.payment}
                  onChange={(e) => {
                    props.saveTicketFormDetails({ name: "payment", value: e })
                    if (e.value !== 'check') {
                      props.saveTicketFormDetails({
                        name: "paymentCheckAmount",
                        value: ""
                      });
                    }
                  }}
                />
                {props.metroFormDetails.payment.value === "check" && (
                  <>
                    <Label className="ml-2">Check Amount:</Label>
                    <Input
                      type="number"
                      id="paymentCheckAmount"
                      className="ml-1"
                      placeholder="Check Amount"
                      autoComplete="off"
                      name="paymentCheckAmount"
                      value={props.metroFormDetails.paymentCheckAmount}
                      onChange={(e) =>
                        props.saveTicketFormDetails({
                          name: e.target.name,
                          value: e.target.value,
                        })
                      }
                    />
                    <Label className="ml-2">Check Number:</Label>
                    {(props.userType === 'admin') && (
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <Button
                            size="sm"
                            className="ml-1"
                            color="danger"
                            title="Set Check Number Prefix"
                            onClick={(e) => {
                              e.preventDefault();
                              //
                              props.history.push("/portal/setting-checks");
                            }}>
                            <i class="fas fa-money-check-alt"></i>
                          </Button>
                        </InputGroupAddon>
                        <Input
                          id="checkNumber"
                          type="text"
                          placeholder="Check Number"
                          autoComplete="off"
                          name="checkNumber"
                          value={props.metroFormDetails.checkNumber}
                          onChange={(e) => {
                            props.saveTicketFormDetails({
                              name: e.target.name,
                              value: e.target.value,
                            });
                          }}
                          />
                    </InputGroup>
                    )}
                    {(props.userType !== 'admin') && (
                        <Input
                        id="checkNumber"
                        type="text"
                        placeholder="Check Number"
                        autoComplete="off"
                        name="checkNumber"
                        className="ml-1"
                        value={props.metroFormDetails.checkNumber}
                        onChange={(e) => {
                          let characterLength = e.target.value.length
                          let checkNumberPrefixLength = checkNumberPrefix.length
                          if (characterLength >= checkNumberPrefixLength) {
                            props.saveTicketFormDetails({
                              name: e.target.name,
                              value: e.target.value,
                            });
                          }
                        }}
                        />
                    )}
                  </>
                )}
              </div>
            </td>
            {props.metroFormDetails.payment.value !== "check" && (
              <td className="width-last"></td>
            )}
          </tr>
          <tr>
            <th>First Name:</th>
            <td className="width-middle">
              <Input
                id="firstName"
                type="text"
                placeholder="First Name"
                name="firstName"
                autoComplete="off"
                value={props.metroFormDetails.firstName}
                onChange={changeFirstNameAndLastNameHandler}
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th className="back-grey">Middle Name:</th>
            <td className="width-middle">
              <Input
                id="middleName"
                type="text"
                placeholder="Middle Name"
                name="middleName"
                autoComplete="off"
                value={props.metroFormDetails.middleName}
                onChange={(e) =>
                  props.saveTicketFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>Last Name:</th>
            <td className="width-middle">
              <Input
                id="lastName"
                type="text"
                placeholder="Last Name"
                name="lastName"
                autoComplete="off"
                value={props.metroFormDetails.lastName}
                onChange={changeFirstNameAndLastNameHandler}
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-grey">
            <th>Address:</th>
            <td className="width-middle">
              <Input
                id="address"
                type="text"
                placeholder="Address"
                name="address"
                autoComplete="off"
                value={props.metroFormDetails.address}
                onChange={(e) =>
                  props.saveTicketFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>City:</th>
            <td className="width-middle">
              <Input
                id="city"
                type="text"
                placeholder="City"
                name="city"
                autoComplete="off"
                value={props.metroFormDetails.city}
                onChange={(e) =>
                  props.saveTicketFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-grey">
            <th>State:</th>
            <td className="width-middle">
              <Input
                id="state"
                type="text"
                placeholder="State"
                name="state"
                autoComplete="off"
                onInput={(e) => {
                  e.target.value = e.target.value.toString().slice(0, 2);
                }}
                value={props.metroFormDetails.state}
                onChange={(e) =>
                  props.saveTicketFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>Zip:</th>
            <td className="width-middle">
              <Input
                id="zip"
                type="number"
                placeholder="Zip"
                name="zip"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 12);
                }}
                value={props.metroFormDetails.zip}
                onChange={(e) =>
                  props.saveTicketFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-grey">
            <th>Sex:</th>
            <td className="width-middle">
              <div className="d-flex align-items-end">
                <DropdownList
                  id="sex"
                  data={sexOptions}
                  containerClassName="payment-drop"
                  textField="label"
                  valueField="value"
                  value={props.metroFormDetails.sex}
                  onChange={(e) =>
                    props.saveTicketFormDetails({ name: "sex", value: e })
                  }
                />
              </div>
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>DOB:</th>
            <td className="width-middle">
              <Input
                id="dOB"
                type="number"
                placeholder="DOB"
                name="dOB"
                value={props.metroFormDetails.dOB}
                onChange={(e) => {
                  const formattedDOB = moment(e.target.value, 'MMDDYYYY');
                  if (formattedDOB.isValid() && e.target.value.length === 8) {
                    const yearOfBirth = moment().diff(formattedDOB, 'years');
                    //check less than 18
                    props.blocked((yearOfBirth < 18 && yearOfBirth > 0));
                    setFormBlocked((yearOfBirth < 18 && yearOfBirth > 0));
                    props.censored((yearOfBirth < 18 && yearOfBirth > 0))
                  } else {
                    props.blocked(false);
                    setFormBlocked(false);
                    props.censored(false)
                  }
                  props.saveTicketFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }}
                autoComplete="off"
              />
            </td>
            <td className="width-last">
              <span>Example: MMDDYYYY 12311980.</span>
            </td>
          </tr>
          <tr className="back-grey">
            <th>Height:</th>
            <td className="width-middle">
              <Input
                id="height"
                type="number"
                placeholder="Height"
                name="height"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 3);
                }}
                value={props.metroFormDetails.height}
                onChange={(e) =>
                  props.saveTicketFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </td>
            <td className="width-last">
              <span>Example: If someone is 6'3" put 603.</span>
            </td>
          </tr>
          <tr>
            <th>Weight:</th>
            <td className="width-middle">
              <Input
                id="weight"
                type="number"
                placeholder="Weight"
                name="weight"
                value={props.metroFormDetails.weight}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 3);
                }}
                onChange={(e) =>
                  props.saveTicketFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-grey">
            <th>Eyes:</th>
            <td className="width-middle">
              <div className="d-flex align-items-end">
                <DropdownList
                  id="eyes"
                  data={eyesOptions}
                  containerClassName="payment-drop"
                  textField="label"
                  value={props.metroFormDetails.eyes}
                  onChange={(e) =>
                    props.saveTicketFormDetails({ name: "eyes", value: e })
                  }
                />
              </div>
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>Hair:</th>
            <td className="width-middle">
              <div className="d-flex align-items-end">
                <DropdownList
                  id="hair"
                  data={hairOptions}
                  containerClassName="payment-drop"
                  textField="label"
                  value={props.metroFormDetails.hair}
                  onChange={(e) =>
                    props.saveTicketFormDetails({ name: "hair", value: e })
                  }
                />
              </div>
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-red ">
            <th>Race:</th>
            <td className="width-middle">
              <div className="d-flex align-items-end">
                <DropdownList
                  id="race"
                  data={raceOptions}
                  containerClassName="race-drop"
                  textField="label"
                  valueField="value"
                  value={props.metroFormDetails.race}
                  onChange={(e) =>
                    props.saveTicketFormDetails({ name: "race", value: e })
                  }
                />
              </div>
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>DL State:</th>
            <td className="width-middle">
              <Input
                id="dLState"
                type="text"
                placeholder="DL State"
                name="dLState"
                value={props.metroFormDetails.dLState}
                onInput={(e) => {
                  e.target.value = e.target.value.slice(0, 2);
                }}
                onChange={(e) =>
                  props.saveTicketFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-grey">
            <th>DL Number:</th>
            <td className="width-middle">
              <Input
                id="dLNo"
                type="text"
                placeholder="DL Number"
                name="dLNo"
                value={props.metroFormDetails.dLNo}
                onChange={changeDlAndOtherNumberHandler}
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-grey">
            <th>DL Expiration Date:</th>
            <td className="width-middle">
            <Input
              id="dLExpDate"
              type="text"
              value={props.metroFormDetails.dLExpirationDate}
              name="dLExpirationDate"
              placeholder="DL Expiration Date"
              onChange={(e) => {
                props.blocked(moment().unix() > moment(e.target.value).unix())
                //
                props.saveTicketFormDetails({
                  name: e.target.name,
                  value: e.target.value,
                })
              }}
            />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>Other ID Type:</th>
            <td className="width-middle">
              <Input
                id="otherIDType"
                type="text"
                placeholder="Other ID Type"
                name="otherIDType"
                value={props.metroFormDetails.otherIDType}
                onChange={(e) =>
                  props.saveTicketFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-grey">
            <th>Other ID Number:</th>
            <td className="width-middle">
              <Input
                id="otherIDNo"
                type="text"
                placeholder="Other ID Number"
                name="otherIDNo"
                value={props.metroFormDetails.otherIDNo}
                onChange={changeDlAndOtherNumberHandler}
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-red">
            <th>From:</th>
            <td className="width-middle">
              <div className="d-flex align-items-end">
                <DropdownList
                  id="fromx"
                  data={fromOptions}
                  containerClassName="from-drop"
                  textField="name"
                  value={props.metroFormDetails.fromx}
                  onChange={(e) =>
                    props.saveTicketFormDetails({ name: "fromx", value: e })
                  }
                />
              </div>
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-red ">
            <th>Booth:</th>
            <td className="width-middle">
              <div className="d-flex align-items-end">
                <DropdownList
                  id="booth"
                  data={boothOptions}
                  containerClassName="booth-drop"
                  textField="name"
                  value={props.metroFormDetails.booth}
                  onChange={(e) => {
                    props.saveTicketFormDetails({ name: "booth", value: e });
                  }}
                />
              </div>
            </td>
            <td className="width-last"></td>
          </tr>
          { props.userType && !["employee", "employees", "employe"].includes(
           props.userType.toLowerCase()
          ) && (
            <tr>
              <th>Station:</th>
              <td className="width-middle">
                <div className="d-flex align-items-end">
                  <DropdownList
                    id="station"
                    data={stationOptions}
                    containerClassName="station-drop"
                    textField="label"
                    value={props.metroFormDetails.station}
                    onChange={(e) => {
                      props.saveTicketFormDetails({
                        name: "station",
                        value: e,
                      });
                    }}
                  />
                </div>
              </td>
              <td className="width-last"></td>
            </tr>
          )}
          <tr>
            <th>Keyword:</th>
            <td className="width-middle autocomplete">
              {/* <Autocomplete
                suggestions={keywords}
                value={props.metroFormDetails.keyword}
                getKeywords={(e) => {
                  getKeywords();
                }}
                changeValues={(e) =>
                  props.saveTicketFormDetails({
                    name: "keyword",
                    value: e,
                  })
                }
              /> */}
              <Input
                id="keyword"
                type="text"
                placeholder="Keyword"
                name="keyword"
                value={props.metroFormDetails.keyword}
                onChange={(e) =>
                  props.saveTicketFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr>
            <th>Coupon:</th>
            <td className="width-middle">
              <Input
                id="coupon"
                type="text"
                placeholder="Coupon"
                name="coupon"
                value={props.metroFormDetails.coupon}
                onChange={(e) =>
                  props.saveTicketFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
                autoComplete="off"
              />
            </td>
            <td className="width-last"></td>
          </tr>
          <tr className="back-red booth-drop">
            <th>Clerk Name:</th>
            <td className="width-middle">
              <div className="d-flex align-items-end">
                <DropdownList
                  id="clerk"
                  data={props.clerks.allClerks}
                  containerClassName="booth-drop"
                  dropUp={true}
                  textField="first_name"
                  value={props.metroFormDetails.clerk}
                  onChange={(e) =>
                    props.saveTicketFormDetails({ name: "clerk", value: e })
                  }
                />
              </div>
            </td>
            <td className="width-last"></td>
          </tr>
          {/*<tr>
            <th>Print labels:</th>
            <td className="width-middle copies d-flex align-items-center">
              <Label className="mb-0 mr-1">Copies:</Label>
              <Input
                id="copies"
                type="number"
                placeholder="Copies"
                value={props.metroFormDetails.copies}
                onChange={(e) => {
                  props.saveTicketFormDetails({
                    name: "copies",
                    value: e.target.value,
                  });
                }}
              />
            </td>
            <td className="width-last"></td>
          </tr>*/}
          <tr>
            <th>Attachments:</th>
            <td className="width-middle">
              <input type="file" name="files[]" id="fileuploader"/>
            </td>
            <td className="width-last"></td>
          </tr>
          {(props.userType === 'admin') && (
            <tr>
              <th>Video URL:</th>
              <td className="width-middle">
              <Input
                id="videoUrl"
                type="text"
                placeholder="Video URL"
                name="videoUrl"
                value={props.metroFormDetails.videoUrl}
                onChange={(e) =>
                  props.saveTicketFormDetails({
                    name: e.target.name,
                    value: e.target.value,
                  })
                }
              />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Form>
  );
}

export default AddMetroTicketForm;
