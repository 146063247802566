import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
} from "reactstrap";

function MissingDepositModal(props) {
    const [newItems, setNewItems] = useState(props.items);
    const [depositDate, setDepositDate] = useState();
    useEffect(() => {
      setNewItems(newItems.map(v=>{
        v.depositDone = true
        return v
      }))
      setDepositDate(moment().format("YYYY-MM-DD"));
    }, []);
    
    const renderMissingDays = (e) => {
        return newItems.map((item, i) => {
          return (
            <div className="" key={i}>
                <input
                    id={"depositDone" + i}
                    type="checkbox"
                    checked={item.depositDone}
                    className="db-sized-checkbox"
                    onChange={(e) => {
                        const { checked } = e.target;
                        //let newItems = items
                        newItems[i].depositDone = checked
                        setNewItems([... newItems])
                    }}
                    />&nbsp;&nbsp;&nbsp;
                <strong>{item.dailyDate}</strong>
            </div>
          );
        });
    };

    const cancelDeposit = () => {
      props.toggle()
      setNewItems(newItems.map(v=>{
        v.depositDone = false
        return v
      }))
    }

  return (
    <Modal isOpen={props.show} toggle={cancelDeposit} className={props.class ? props.class : ""}>
      <ModalHeader toggle={cancelDeposit}>Confirmation!</ModalHeader>
      <ModalBody className="py-0">
        <div className="form-group text-center">
            <>
              <Label className="form-control-label mb-0">Deposit Date:</Label>
              <Input
                type="date"
                value={depositDate}
                onChange={(e) => setDepositDate(e.target.value)}
                style={{width: '300px', margin: 'auto'}}
              />
                <h4>Do you want also deposit the following days?</h4>
                {renderMissingDays()}
            </>
        </div>
      </ModalBody>
      <ModalFooter className="pt-0">
        <Button id="confirm-btn" color="primary" onClick={e => props.confirmed(newItems, true, depositDate)}>
          Confirm
        </Button>{" "}
        <Button
          color="secondary"
          onClick={e =>cancelDeposit()}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

export default MissingDepositModal;
