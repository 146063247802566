import {
    FETCH_KEYWORDS_LISTING_SUCCESS,
    FETCH_KEYWORDS_LISTING_FAILURE,
    RESET,
  } from "./keywordsTypes";
  
  const initialState = {
    allKeywords: [],
    error: null,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_KEYWORDS_LISTING_SUCCESS:
        return {
          allKeywords: action.payload.data,
          count:action.payload.count,
          page: action.payload.page,
          error: null,
        };
      case FETCH_KEYWORDS_LISTING_FAILURE:
        return {
          allKeywords: [],
          count: 0,
          page: 1,
          error: action.payload,
        };
      case RESET: {
        return initialState;
      }
      default:
        return state;
    }
  };
  
  export default reducer;
  