import Dashboard from "views/Dashboard/Dashboard";
import CustomerHome from "views-customer/CustomerHome";
import Login from "views/Login/Login";
import UserManagement from "./views/UserManagement/UserManagement";
import UserGroup from "./views/UserManagement/UserGroup";
import ProfileUpdate from "./views/ProfileUpdate/ProfileUpdate";
import MetroTicket from "./views/MetroTicket/MetroTicket";
import AddNewMetroTicket from "./views/MetroTicket/AddNew/AddNewMetroTicket";
import EditMetroTicket from "./views/MetroTicket/EditMetroTicket";
import ViewItems from "./views/MetroTicket/ViewItems/ViewItems";
import BlockList from "views/BlockList/BlockList";
import CoinTicket from "views/CoinTicket/CoinTicket";
import AddNewCoinTicket from "views/CoinTicket/AddNew/AddNewCoinTicket";
import EditCoinTicket from "views/CoinTicket/Edit/EditCoinTicket";
import Buyers from "views/Buyers/Buyers";
import CoinItems from "views/Items/Items";
import ItemsCategories from "views/Items/ItemsCategories";
import NotAnyPermissions from "views/Pages/NotAnyPermissions";
import SignaturePad from "views/SignaturePad/SignaturePad";
import SignaturePad2 from "views/SignaturePad/SignaturePad2";
import Receipts from "views/Receipts/Receipts";
import CreateNewReceipt from "views/Receipts/CreateNewReceipt";
import CreateNewJewelryReceipt from "views/JewelryReceipts/CreateNewReceipt";
import UpdateJewelryReceipt from "views/JewelryReceipts/UpdateReceipt";
import UpdateReceipt from "views/Receipts/UpdateReceipt";
import Clerks from "views/Clerks/Clerks";
import FromOptions from "views/FromOptions/FromOptions";
import VoidTickets from "views/VoidTickets/VoidTickets";
import LeadsOnlineSetting from "views/LeadsOnline/Settings";
import Notices from "views/Notices/Notices";
import Daily from "views/Daily/Daily";
import DayStat from "views/Daily/DayStat";
import HistoricalChartView from "views/HistoricalChartView/HistoricalChartView";
import AdvanceSearch from "views/AdvanceSearch/AdvanceSearch";
import IpWhitelist from "views/IpWhitelist/IpWhitelist";
import JewelryReceipts from "views/JewelryReceipts/JewelryReceipts";
import Checks from "views/Checks/Checks";
import Taxes from "views/Taxes/Taxes";
import CoinReceiptDeposit from "views/Deposit/CoinReceipt";
import Booth from "views/BoothSection/Booth";
import Metals from "views/Metals/Metals";
import PettyCashUsers from "views/PettyCashUsers/PettyCashUsers";
import Transactions from "views/Transactions/Transactions";
import TransactionPetty from "views/Transactions/TransactionPetty";
import Crypto from "views/Crypto/Crypto";
import PettyCashReport from "views/PettyCashReport/PettyCashReport";
import SignatureDashboard from "views/SignatureDashboard/SignatureDashboard";
import Invoice from "views/Invoice/Invoice";
import CoinInvoice from "views/Invoice/CoinInvoice";
import CreateNewInvoice from "views/Invoice/CreateNewInvoice";
import CreateNewCoinInvoice from "views/Invoice/CreateNewCoinInvoice";
import TransactionDaily from "views/Transactions/TransactionDaily";
import TransactionReconcile from "views/Transactions/TransactionReconcile";
import TransactionDrawerTransfer from "views/Transactions/TransactionDrawerTransfer";
import TransactionDrawerSnapshot from "views/Transactions/TransactionDrawerSnapshot";
import TransactionWorksheet from "views/Transactions/TransactionWorksheet";
import Locks from "views/Locks/Locks";
import Demographics from "views/Demographics/Demographics";
import Keywords from "views/Keywords/Keywords";
import Refer from "views/Refer/Refer";
import HighValueTickets from "views/HighValueTickets/HighValueTickets";
import Quotes from "views/Quotes/Quotes";
import QuoteSave from "views/Quotes/QuoteSave";
import Metrics from "views/Metrics/Metrics";
import CheckSetting from "views/Checks/CheckSetting";
import MetricIgnore from "views/Metrics/MetricIgnore";
import ItemDescriptionCheck from "views/ItemDescriptionCheck/ItemDescriptionCheck";
import VoidLoggerReason from "views/VoidTickets/VoidReason";
import DrawerDisparityLog from "views/DrawerDisparityLog/DrawerDisparityLog";
import Payees from "views/Payees/Payees";
import Printers from "views/Printers/Printers";
import EmailNotification from "views/Setting/EmailNotification";
import TabletConfig from "views/TabletConfig";
import DailyProfit from "views/Daily/DailyProfit";
import DailyTally from "views/Daily/DailyTally";

var routes = [
  {
    path: "/home",
    name: "CustomerHome",
    showOnSidebar:false,
    exact:false,
    icon: "fas fa-tachometer-alt",
    component: CustomerHome,
    group: 'adminTools',
    adminTools:false,
    accessCode:"customer_dashboard",
    layout: "/customer-portal"
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    showOnSidebar:false,
    exact:false,
    icon: "fas fa-tachometer-alt",
    component: Dashboard,
    group: 'adminTools',
    adminTools:false,
    accessCode:"dashboard",
    layout: "/portal"
  },
  //__________________Show On Sidebars______________

  /*** MAIN MENU OPTIONS **/
  {
    path: "/checks",
    name: "Checks",
    icon: "fas fa-money-check-alt",
    component: Checks,
    accessCode:"admin_only",
    layout: "/portal",
    group: 'root',
    adminTools:false,
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/transactions/daily",
    name: "Count",
    icon: "fas fa-calculator",
    component: TransactionDaily,
    accessCode:"transaction",
    group: 'root',
    layout: "/portal",
    adminTools:false,
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/daily/:year?/:month?",
    displayUrl: "/daily",
    name: "Daily",
    icon: "fas fa-chart-line",
    component: Daily,
    accessCode:"admin_only",
    group: 'root',
    layout: "/portal",
    adminTools:false,
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/profit/:year/:month/:day",
    name: "DailyProfit",
    icon: "fas fa-chart-line",
    component: DailyProfit,
    accessCode:"admin_only",
    group: 'root',
    layout: "/portal",
    adminTools:false,
    showOnSidebar:false,
    exact:true,
  },
  {
    path: "/tally/:year/:month/:day",
    name: "DailyTally",
    icon: "fas fa-chart-line",
    component: DailyTally,
    accessCode:"admin_only",
    group: 'root',
    layout: "/portal",
    adminTools:false,
    showOnSidebar:false,
    exact:true,
  },
  {
    path: "/invoices",
    name: "Invoices",
    icon: "fas fa-file-invoice",
    component: Invoice,
    accessCode:"admin_only",
    layout: "/portal",
    group: 'root',
    adminTools:false,
    showOnSidebar:true,
    exact:true
  },
  {
    path: "/coin-invoices",
    name: "Coin Invoices",
    icon: "fas fa-file-invoice",
    component: CoinInvoice,
    accessCode:"coin_invoice",
    layout: "/portal",
    group: 'root',
    adminTools:false,
    showOnSidebar:true,
    exact:true
  },
  {
    path: "/locks",
    name: "Locks",
    icon: "fas fa-lock",
    component: Locks,
    accessCode:"admin_only",
    layout: "/portal",
    group: 'root',
    adminTools:false,
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/metals",
    name: "Metals",
    icon: "fas fa-ring",
    component: Metals,
    accessCode:"admin_only",
    layout: "/portal",
    group: 'root',
    adminTools:false,
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/notices",
    name: "Notices",
    icon: "fas fa-bell",
    component: Notices,
    accessCode:"notice_view",
    group: 'root',
    layout: "/portal",
    adminTools:false,
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/transactions-petty",
    name: "Petty Cash Transactions",
    icon: "fas fa-arrows-alt-h",
    component: TransactionPetty,
    accessCode:"transaction",
    layout: "/portal",
    group: 'root',
    adminTools:false,
    showOnSidebar:true,
    hideOnAdmin: true,
    exact:true,
  },
  {
    path: "/refer",
    name: "Refer",
    icon: "fab fa-wordpress-simple",
    component: Refer,
    accessCode:"admin_only",
    layout: "/portal",
    group: 'root',
    adminTools:false,
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/signature-dashboard",
    name: "Signature Dashboard",
    icon: "fas fa-signature",
    component: SignatureDashboard,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    group: 'root',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/transactions",
    name: "Transactions",
    icon: "fas fa-arrows-alt-h",
    component: Transactions,
    accessCode:"admin_only",
    layout: "/portal",
    group: 'root',
    adminTools:false,
    showOnSidebar:true,
    exact:true,
  },
  /*** END MAIN MENU OPTIONS **/

  /*** CPA MENU OPTIONS */
  {
    path: "/highValueTickets",
    name: "High Value Tickets",
    icon: "fas fa-layer-group",
    component: HighValueTickets,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    group: 'cpa',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/petty-cash-report",
    name: "Petty Cash Reports",
    icon: "fas fa-cash-register",
    component: PettyCashReport,
    accessCode:"admin_only",
    layout: "/portal",
    showOnSidebar:true,
    group: 'cpa',
    adminTools:true,
    exact:true,
  },
  {
    path: "/transactions-petty",
    name: "Transactions Petty",
    icon: "fas fa-arrows-alt-h",
    component: TransactionPetty,
    accessCode:"admin_only",
    layout: "/portal",
    showOnSidebar:true,
    group: 'cpa',
    adminTools:true,
    exact:true,
  },
  {
    path: "/taxes",
    name: "Taxes",
    icon: "fas fa-hand-holding-usd",
    component: Taxes,
    accessCode:"admin_only",
    layout: "/portal",
    group: 'cpa',
    adminTools:false,
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/coin-receipt-deposit",
    name: "CR Deposit",
    icon: "fas fa-cash-register",
    component: CoinReceiptDeposit,
    accessCode:"admin_only",
    layout: "/portal",
    group: 'cpa',
    adminTools:false,
    showOnSidebar:true,
    exact:true,
  },
  /*** END CPA MENU OPTIONS */

  {
    path: "/metro-tickets",
    name: "Metro Tickets",
    icon: "fas fa-ring",
    component: MetroTicket,
    accessCode:"ticket",
    layout: "/portal",
    adminTools:false,
    showOnSidebar:false,
    exact:true,
  },
  {
    path: "/coin-tickets",
    name: "Coin Tickets",
    icon: "fas fa-coins",
    component: CoinTicket,
    accessCode:"new_coin",
    layout: "/portal",
    adminTools:false,
    showOnSidebar:false,
    exact:true,
  },
  {
    path: "/receipts",
    name: "Receipts",
    icon: "fas fa-receipt",
    component: Receipts,
    accessCode:"receipt",
    layout: "/portal",
    adminTools:false,
    showOnSidebar:false,
    exact:true,
  },
  {
    path: "/jewelry-receipts",
    name: "Jewelry Receipts",
    icon: "fas fa-receipt",
    component: JewelryReceipts,
    accessCode:"receipt",
    layout: "/portal",
    adminTools:false,
    showOnSidebar:false,
    exact:true,
  },
  {
    path: "/metrics/ignore",
    name: "MetricIgnore",
    component: MetricIgnore,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    showOnSidebar:false,
    exact:false
  },
  {
    path: "/quotes/create",
    name: "New Quote",
    component: QuoteSave,
    accessCode:"admin_only",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/quotes/edit/:id",
    name: "Edit Quote",
    component: QuoteSave,
    accessCode:"admin_only",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/invoices/create-new",
    name: "Create Invoice",
    component: CreateNewInvoice,
    accessCode:"invoice",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/invoices/edit/:id",
    name: "Update Invoice",
    component: CreateNewInvoice,
    accessCode:"invoice",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/coin-invoices/create-new",
    name: "Create Invoice",
    component: CreateNewCoinInvoice,
    accessCode:"invoice",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/coin-invoices/edit/:id",
    name: "Update Invoice",
    component: CreateNewCoinInvoice,
    accessCode:"invoice",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/transactions/daily",
    name: "Transactions Daily",
    icon: "fas fa-arrows-alt-h",
    component: TransactionDaily,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/transactions/reconcile",
    name: "Transactions Reconcile",
    icon: "fas fa-arrows-alt-h",
    component: TransactionReconcile,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/transactions/drawer-transfer",
    name: "Transactions Drawer Transfer",
    icon: "fas fa-arrows-alt-h",
    component: TransactionDrawerTransfer,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/transactions/snapshot",
    name: "Transactions Drawer Snapshot",
    icon: "fas fa-arrows-alt-h",
    component: TransactionDrawerSnapshot,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/transactions/worksheet",
    name: "Transactions Worksheet",
    icon: "fas fa-arrows-alt-h",
    component: TransactionWorksheet,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    showOnSidebar:false,
    exact:false,
  },

  /*_________________________________________ */

  {
    path: "/user-management/groups",
    name: "User Management",
    component: UserGroup,
    accessCode:"user_manage",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/metro-tickets/add-new",
    name: "Add Metro Ticket",
    component: AddNewMetroTicket,
    accessCode:"ticket",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/metro-tickets/edit/:id",
    name: "Edit Metro Ticket",
    component: EditMetroTicket,
    accessCode:"ticket",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/metro-tickets/view-items/:id",
    name: "View Metro Ticket Items",
    component: ViewItems,
    accessCode:"ticket",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/coin-tickets/add-new",
    name: "Add Coin Ticket",
    component: AddNewCoinTicket,
    accessCode:"new_coin",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/coin-tickets/edit/:id",
    name: "Edit Coin Ticket",
    component: EditCoinTicket,
    accessCode:"new_coin",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/coin-items/categories",
    name: "Items Categories",
    icon: "fas fa-layer-group",
    component: ItemsCategories,
    accessCode:"new_coin",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/my-profile",
    name: "My Profile",
    component: ProfileUpdate,
    accessCode:"all",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/receipts/create-new",
    name: "Create Receipt",
    component: CreateNewReceipt,
    accessCode:"receipt",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/receipts/edit/:id",
    name: "Edit Receipt",
    component: UpdateReceipt,
    accessCode:"receipt",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/jewelry-receipts/create-new",
    name: "Create Jewelry Receipt",
    component: CreateNewJewelryReceipt,
    accessCode:"receipt",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  
  {
    path: "/jewelry-receipts/edit/:id",
    name: "Edit Jewelry Receipt",
    component: UpdateJewelryReceipt,
    accessCode:"receipt",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/daily/historical-chart",
    name: "Historical Chart",
    component: HistoricalChartView,
    accessCode:"all",
    layout: "/portal",
    showOnSidebar:false,
    exact:false,
  },
  {
    path: "/not-any-permissions",
    name: "No Permissions",
    icon: "ni ni-key-25 text-info",
    component: NotAnyPermissions,
    layout: "/portal"
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },

  /** ----------- BEGIN SETTING SECTION ------------ */
  //------------ 1
  {
    path: "/setting-checks",
    name: "Checks",
    icon: "fas fa-money-check-alt",
    component: CheckSetting,
    accessCode:"user_manage",
    adminTools:false,
    layout: "/portal",
    group: 'adminTools1',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/payees",
    name: "Payees",
    icon: "fas fa-users",
    component: Payees,
    accessCode:"user_manage",
    adminTools:false,
    layout: "/portal",
    group: 'adminTools1',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/printers",
    name: "Printers",
    icon: "fas fa-print",
    component: Printers,
    accessCode:"user_manage",
    adminTools:false,
    layout: "/portal",
    group: 'adminTools1',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/date-stats",
    displayUrl: "/date-stats",
    name: "Date",
    icon: "fas fa-calendar-day",
    component: DayStat,
    accessCode:"admin_only",
    group: 'adminTools1',
    layout: "/portal",
    adminTools:false,
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/drawer-disparity-log",
    name: "Drawer Disparity",
    icon: "fas fa-user-lock",
    component: DrawerDisparityLog,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    group: 'adminTools1',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/setting-email-notification",
    name: "Email Noifications",
    icon: "fas fa-bell",
    component: EmailNotification,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    group: 'adminTools1',
    showOnSidebar:true,
    exact:true,
  },
  //------------------- 2
  {
    path: "/block-list",
    name: "Block List",
    icon: "fas fa-user-lock",
    component: BlockList,
    accessCode:"all",
    layout: "/portal",
    adminTools:false,
    group: 'adminTools2',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/buyers",
    name: "Buyers",
    icon: "fas fa-shopping-basket",
    component: Buyers,
    accessCode:"user_manage",
    layout: "/portal",
    adminTools:false,
    group: 'adminTools2',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/clerk-listings",
    name: "Clerks",
    icon: "fas fa-users",
    component: Clerks,
    accessCode:"clerks",
    layout: "/portal",
    adminTools:false,
    group: 'adminTools2',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/petty-cash-users",
    name: "Petty Cash Users",
    icon: "fas fa-users",
    component: PettyCashUsers,
    accessCode:"admin_only",
    layout: "/portal",
    group: "adminTools2",
    showOnSidebar:true,
    adminTools:true,
    exact:true,
  },
  {
    path: "/user-management",
    name: "User Management",
    icon: "fas fa-users",
    component: UserManagement,
    accessCode:"user_manage",
    adminTools:false,
    layout: "/portal",
    group: 'adminTools2',
    showOnSidebar:true,
    exact:true,
  },
  //------------------ 3
  {
    path: "/advance-search",
    name: "Advance Search",
    icon: "fas fa-search",
    component: AdvanceSearch,
    accessCode:"advance_search",
    layout: "/portal",
    adminTools:false,
    group: 'adminTools3',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/booth",
    name: "Booth",
    icon: "fas fa-person-booth",
    component: Booth,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    group: 'adminTools3',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/crypto",
    name: "Crypto",
    icon: "fab fa-bitcoin",
    component: Crypto,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    group: 'adminTools3',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/demographics",
    name: "Demographics",
    icon: "fas fa-globe",
    component: Demographics,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    group: 'adminTools3',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/item-descriptions",
    name: "Description Checker",
    icon: "fas fa-users",
    component: ItemDescriptionCheck,
    accessCode:"admin_only",
    adminTools:false,
    layout: "/portal",
    group: 'adminTools3',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/from-options",
    name: "From Option",
    icon: "fas fa-layer-group",
    component: FromOptions,
    accessCode:"from_options",
    layout: "/portal",
    adminTools:false,
    group: 'adminTools3',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/items",
    name: "Items",
    icon: "fas fa-layer-group",
    component: CoinItems,
    accessCode:"coin_item",
    layout: "/portal",
    adminTools:false,
    group: 'adminTools3',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/ip-whitelist",
    name: "IP Whitelist",
    icon: "fas fa-unlock-alt",
    component: IpWhitelist,
    accessCode:"ip_whitelist",
    layout: "/portal",
    adminTools:false,
    group: 'adminTools3',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/keywords",
    name: "Keywords",
    icon: "fas fa-paragraph",
    component: Keywords,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    group: 'adminTools3',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/metrics",
    name: "Metrics",
    icon: "fas fa-chart-area",
    component: Metrics,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    group: 'adminTools3',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/quotes",
    name: "Quotes",
    icon: "fas fa-layer-group",
    component: Quotes,
    accessCode:"admin_only",
    layout: "/portal",
    adminTools:false,
    group: 'adminTools3',
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/signature-pad",
    name: "Signature",
    icon: "fas fa-signature",
    component: SignaturePad,
    accessCode:"sig_pad",
    layout: "/portal",
    group: "adminTools3",
    showOnSidebar:true,
    adminTools:true,
    exact:true,
  },
  {
    path: "/signature-pad-2",
    name: "Signature V2",
    icon: "fas fa-signature",
    component: SignaturePad2,
    accessCode:"sig_pad",
    layout: "/portal",
    group: "adminTools3",
    showOnSidebar:true,
    adminTools:true,
    exact:true,
  }, 
  {
    path: "/void-logger",
    name: "Void Tickets",
    icon: "fas fa-layer-group",
    component: VoidTickets,
    accessCode:"void_logger",
    group: 'adminTools3',
    layout: "/portal",
    adminTools:false,
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/leads-online-rules",
    name: "LeadsOnline Rules",
    icon: "fas fa-layer-group",
    component: LeadsOnlineSetting,
    accessCode:"admin_only",
    group: 'adminTools3',
    layout: "/portal",
    adminTools:false,
    showOnSidebar:true,
    exact:true,
  },
  {
    path: "/void-logger/reasons",
    name: "All Reasons",
    icon: "fas fa-layer-group",
    component: VoidLoggerReason,
    accessCode:"void_logger",
    group: 'adminTools3',
    layout: "/portal",
    adminTools:false,
    showOnSidebar:false,
    exact:true,
  },
  {
    path: "/tablet-config",
    name: "Table Configuration",
    icon: "",
    component: TabletConfig,
    accessCode:"ticket",
    group: 'adminTools3',
    layout: "/portal",
    adminTools:false,
    showOnSidebar:false,
    exact:true,
  },
  /** ---------------- END SETTING SECTION ------------------ */
];
export default routes;