import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Table,
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap";
import Unauthorized from "views/Pages/UnauthorizedCustomer";
import {
    billedToOptions,
    invoicePhoneNumbers,
    invoiceBusinessLicences
} from "../../constants/ticketConstants";
import { resetState } from "redux/common/commonAction";
import {
    fetchSingleCoinInvoice,
    saveCustomerItemsDetails
} from "../../redux/Invoices/InvoicesAction";
import moment from 'moment-timezone';
import Moment from "react-moment";
import '../../assets/css/view-invoice.css'
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import NumberFormat from "react-number-format";
import MakeOfferModal from "views/Modal/Offer/MakeOfferModal";
import qs from "query-string";

function EditInvoice(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [displayMakeOfferModal, setDisplayMakeOfferModal] = useState(false);
    const [selectedItemId, setSelectedId] = useState(0);
    const [offer, setOffer] = useState({});
    const [viewMode, setViewMode] = useState("");
    const { match: { params } } = props;

    useEffect(() => {
        let viewMode = qs.parse(window.location.search, { ignoreQueryPrefix: true }).mode || "";
        setViewMode(viewMode);
        document.title = 'Customer Invoice';
        props.fetchSingleCoinInvoice(params.id)
    }, []);

    useEffect(() => {
        if (props.invoice.model.id > 0) {
            const expiryTime = moment(props.invoice.model.creationDate * 1000).add(1, 'day').day('Friday').hour(15).minute(0).second(0).unix()
            const todayTime = moment().unix()
            setUnauthorized(false);
            if (todayTime > expiryTime || !props.invoice.model.active) {
                setUnauthorized(true);
            } else {
                setPageLoaded(true)
            }
        }
    }, [props.invoice]);

    const toggleMakeOffer = () => {
        setDisplayMakeOfferModal(!displayMakeOfferModal);
    };

    const updateInvoiceItems = () => {
        PostApiWithAuthorizationAction("invoices/update-items/" + (params.id ? params.id : ""), {
            type: 2,
            items: props.invoice.items
        })
        .then((res) => {
        })
        .catch((err) => {
          setLoading(false);
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
    }

    const submitTempOffer = () => {
        PostApiWithAuthorizationAction("invoices/submit-temp-offer/" + params.id, {
            items: props.invoice.items
        })
          .catch((err) => {
            if (err.status === 440) {
              toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
              props.history.push("/auth/login");
            }
        });        
    }

    const submitOffer = () => {
        setLoading(true);
        PostApiWithAuthorizationAction("invoices/submit-offer/" + params.id, {
            items: props.invoice.items
        })
          .then((res) => {
            setLoading(false);
            toast.success('Offer has been successfully sent', { position: toast.POSITION.TOP_CENTER });
            window.location.reload()
          })
          .catch((err) => {
            setLoading(false);
            if (err.status === 440) {
              toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
              props.history.push("/auth/login");
            }
        });        
    }

    const createOfferHandler = (_params) => {
        let body = {
            amount: _params.amount,
            invoiceId: params.id,
            invoiceItemId: _params.invoiceItemId
        };
        setLoading(true);
        const url = _params.offerId ? "invoices/edit-offer/" + _params.offerId : "invoices/add-new-offer"
        PostApiWithAuthorizationAction(url, body)
          .then((res) => {
            toast.success(_params.offerId ? 'Offer has been successfully reviewed' : 'Offer has been successfully sent', { position: toast.POSITION.TOP_CENTER });
            props.fetchSingleCoinInvoice(params.id)
          })
          .catch((err) => {
            setLoading(false);
            if (err.status === 440) {
              toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
              props.history.push("/auth/login");
            }
          });
      };

    const changeItemValues = (name, value, index) => {
        let temp = props.invoice.items;
        let itemObj = temp[index];
        itemObj[name] = value;
        temp[index] = itemObj;
        if (name === "price") {
            itemObj["price"] = parseFloat(value).toFixed(2);
        }
        if(name=== "offerAmount") {
            console.log(itemObj['Offers'])
            if (itemObj['Offers'].length > 0) {
                itemObj['Offers'][0]['amount'] = parseFloat(value).toFixed(2);
            } else {
                itemObj['Offers'] = [{ amount: parseFloat(value).toFixed(2), approved: 0 }]
            }
        }
        props.saveCustomerItemsDetails(temp);
    };

    //Error Handler from Api and unauthorized check
    if (props.invoice.error && !unauthorized) {
        if (props.invoice.error.status === 403) {
            setUnauthorized(true);
        } else if (props.invoice.error.status === 440) {
            props.resetState();
            toast.error(props.invoice.error.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            props.history.push("/auth/login?r=/coin-invoice/edit/" + params.id);
        }
    }

    const formatAmount = (amount) => {
        var parts = amount.toString().split(".");
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
    }

    const renderAllItemsHTML = (e) => {
        return props.invoice.items.map((item, i) => {
            const qty = !isNaN(item.qty) ? item.qty : 0,
            price = !isNaN(item.price) ? item.price : "",
            offer = item.Offers[0] ? item.Offers[0] : {},
            offerAmount = item.Offers.length > 0 ? offer.amount : "0"
            return (
              <tr key={i} id={"invoice-item-row" + i}>
                {/*<td className="input-td text-center px-0">
                    <Input
                        id="checkbox2"
                        type="checkbox"
                        style={{ position: 'relative', marginLeft: '0', width: '20px' }}
                        checked={item.used}
                        onChange={(e) => {
                            changeItemValues("used", !item.used, i);
                        }}/>
                    </td>*/}
                <td className="input-td" style={{textTransform: 'capitalize'}}>{item.typeId} {item.typeValue}</td>
                <td className="input-td">{qty}</td>
                <td className="input-td">{item.itemDescription}</td>
                {/*<td className="input-td">
                    <strong>$</strong>&nbsp;
                    <NumberFormat
                        id="price"
                        allowNegative={true}
                        className={classnames({ "input-null": price === "", 'form-control': true })}
                        style={{display: 'inline'}}
                        value={price.replace('.00', '')}
                        onValueChange={(e) => {
                            changeItemValues("price", e.value, i);
                            updateInvoiceItems();
                        }}
                        />
                    </td>*/}
                {viewMode == "full" && (
                    <td className="input-td">
                        ${item.estimate.replace('.00', '')}
                    </td>
                )}
                <td className="input-td">
                    <Row>
                        <Col
                            md="9"
                            lg="9"
                            className="pr-1">
                                
                        {(viewMode !== "full" ? (
                           <NumberFormat
                           id="offerAmount"
                           allowNegative={true}
                           className={classnames({ "input-null": offerAmount === "", 'form-control': true })}
                           style={{display: 'inline'}}
                           value={offerAmount.replace('.00', '')}
                           onValueChange={(e) => {
                            submitTempOffer()
                               changeItemValues("offerAmount", e.value, i);
                           }}
                           />
                        ) : "$" + offerAmount.replace('.00', ''))}
                        </Col>
                        { console.log(parseFloat(item.price)) }
                        {props.invoice.model.submitted && (
                            <Col
                                md="3"
                                lg="3"
                                className="pl-0 col-validity pt-1">
                                {((parseFloat(offerAmount) >= parseFloat(item.estimate)) || offer.approved == true || parseFloat(item.price) > 0) ? (
                                    <i className="fas fa-check-circle" style={{color: 'green'}}></i>
                                ) : (
                                    <i className="fas fa-times-circle" style={{color: 'red'}}></i>
                                )}
                            </Col>
                        )}
                    </Row>
                </td>
              </tr>
            );
        });
    };

    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
            {/* Page content */}
            {pageLoaded && (
            <Container className="page-main-content page-view-invoice" fluid>
                <br/>
                <div className="block-center leftmargin">
                    {(props.invoice.model.showInvoiceLogo) && (
                        <>
                            <img style={{maxHeight: '160px'}} src={require("assets/img/LOGO-medium.png")}/>
                            <div className="text-center font-weight-900 font-size-19 grey">
                                4065 S. Jones Blvd <br/>
                                Las Vegas, NV 89103 <br/>
                                702-998-4000
                            </div>
                        </>
                    )}
                    <div className="invoice-title">INVOICE/RECEIPT</div>
                    <div className="font-size-19" style={{marginRight:'120px', float: 'right'}}>
                        {(props.invoice.totalAmount > 0) && (
                            <>
                            <span className="font-weight-900">Total:</span>&nbsp;${formatAmount(props.invoice.totalAmount)}<br/>
                            </>
                        )}
                        <span className="font-weight-900">Gold Spot Price:</span>&nbsp;${formatAmount(parseInt(props.invoice.model.goldSpotPrice))}<br/>
                        <span className="font-weight-900">Silver Spot Price:</span>&nbsp;${formatAmount(parseInt(props.invoice.model.silverSpotPrice))}<br/>
                        <span className="font-weight-900">Platinum Spot Price:</span>&nbsp;${formatAmount(parseInt(props.invoice.model.platinumSpotPrice))}<br/>
                        <span className="font-weight-900">Palladium Spot Price:</span>&nbsp;${formatAmount(parseInt(props.invoice.model.palladiumSpotPrice))}
                    </div>
                    <div className="font-size-19">
                        <div className="">
                            <span className="font-weight-900">Billed To:</span> {billedToOptions[props.invoice.model.billedTo.toLowerCase()]}
                        </div>
                        <div className="">
                            <span className="font-weight-900">Phone #:</span> {invoicePhoneNumbers[props.invoice.model.billedTo.toLowerCase()]}
                        </div>
                        <div className="">
                            <span className="font-weight-900">Business License #:</span> {invoiceBusinessLicences[props.invoice.model.billedTo.toLowerCase()]}
                        </div>
                        <div className="">
                            <span className="font-weight-900">Invoice #:</span> {params.id}
                        </div>
                        <div className="">
                            <span className="font-weight-900">Check #:</span> {props.invoice.model.checkNumber}
                        </div>
                        <div className="">
                            <span className="font-weight-900">Date: </span>
                            <Moment format="MM/DD/YYYY" tz="America/Los_Angeles">
                                {props.invoice.model.creationDate.toString().length === 10
                                        ? new Date(props.invoice.model.creationDate * 1000)
                                        : new Date(props.invoice.model.creationDate)}
                            </Moment>
                        </div>
                    </div>

                    <br/>

___________________________________________________________________________________________________ <br/>
            <div className="text-center py-1 font-weight-600">IF YOU PASS, LEAVE AT ZERO</div>
                </div>
                <Table className="create-invoice-table add-receipt-item-table table table-bordered leftmargin" bordered striped responsive="sm" style={{width: '80%'}}>
                    <thead className="thead-light">
                        <tr>
                            {/*<th scope="col" style={{ width: '50px' }}></th>*/}
                            <th scope="col" className="qty-col">ID</th>
                            <th scope="col" className="qty-col">Qty</th>
                            <th scope="col" className="description-col">Description</th>
                            {/*<th scope="col" className="amount-col">Amount</th>*/}
                            {viewMode == "full" && (
                                <th scope="col" style={{ width: '180px' }}>Min Offer</th>
                            )}
                            <th scope="col" style={{ width: '180px' }}>Offer</th>
                        </tr>
                    </thead>
                    <tbody>{renderAllItemsHTML()}</tbody>
                    <tfoot className="tfoot-light">
                        <tr>
                            <td scope="col" colSpan={3}>Total:</td>
                            <td scope="col">
                                <span style={{ marginTop: '5px' }}>
                                ${formatAmount(props.invoice.items
                                    .reduce((prev, curr) => {
                                        let offer = curr.Offers[0] ? curr.Offers[0] : {},
                                            offerAmount = curr.Offers.length > 0 ? offer.amount : "0"
                                        let price = !isNaN(offerAmount) ? parseFloat(offerAmount) : 0.0
                                        return prev + price;
                                    }, 0)
                                    .toFixed(2)
                                    .replace('.00', ''))}
                                </span>
                                {viewMode !== "full" && (
                                <Button
                                    id="save-receipt"
                                    color="primary"
                                    style={{float: 'right'}}
                                    size="sm"
                                    disabled={loading || props.invoice.model.submitted}
                                    onClick={(e) => {
                                        if (window.confirm('Are you sure that you want to make this offer ?')) {
                                            submitOffer()
                                        }
                                    }}>Submit Offer</Button>
                                )}
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </Container>
        )}
            {displayMakeOfferModal && (
                <MakeOfferModal
                    {...props}
                    show={displayMakeOfferModal}
                    toggle={toggleMakeOffer}
                    invoiceId={params.id}
                    invoiceItemId={selectedItemId}
                    offer={offer}
                />
            )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        invoice: state.invoicesReducer.invoice,
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        fetchSingleCoinInvoice: (id) => dispatch(fetchSingleCoinInvoice(id)),
        saveCustomerItemsDetails: (body) => dispatch(saveCustomerItemsDetails(body)),
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(EditInvoice);
